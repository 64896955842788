import React from "react"
import LayoutNew from "../components/LayoutNew"
import { Helmet } from "react-helmet"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>Terms of Use – Purple Cow Internet 💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
        <h1>Terms of Use</h1>
        <p className="font-bold">
          Please read and agree to these Terms and Conditions of use before
          using Purple Cow Internet Inc’s services or subscribing. This
          agreement is between the subscriber and the service provider, Purple
          Cow Internet Inc. The terms and conditions listed govern subscriber’s
          use of the telecommunications, this Internet site, and/or Internet
          products, long-distance, and all other services offered. All times
          subscriber uses this website or any service listed, subscriber
          accepts, acknowledges, and signifies that subscriber has read,
          understood, and agrees to be accountable to these terms and conditions
          of use. These terms and conditions may be changed and/or updated
          without notice. This page covers conditions applicable to all
          services.
        </p>
        <h3 id="1-the-use-of-www-purplecowinternet-com-and-its-services-">
          1. The Use of www.purplecowinternet.com and its Services:
        </h3>
        <p>
          Purple Cow Internet Inc.’s site and Services offered may only be used
          by any person who has reached the age of majority and who is legally
          applicable to form legal contracts. If under the legal age of majority
          in the jurisdiction in which you live, subscriber must have a parent
          or legal guardian subscribe to the Service on their behalf. Purple Cow
          Internet Inc. reserves the right to terminate/suspend any account
          providing inaccurate or Incomplete information at any time. You agree:
          (i) to not involve, impede, or obstruct other users of the Service;
          (ii) to not impersonate or otherwise misrepresent your affiliation
          with a person/entity while using the Service; (iii) to not use the
          Service with intent of a criminal offense or violation of the rights
          of any party; (iv) to not use the Service to communicate unsolicited
          messages; and (v) to not use the Service for any debasing, derogatory,
          unlawful, slanderous, pornographic, harassing, abusive, threatening,
          or otherwise harmful purpose(s).
        </p>
        <h3 id="2-liability-limitation">2. Liability Limitation</h3>
        <p>
          Purple Cow Internet Inc. has zero control over the content passing
          over Purple Cow Internet Inc.’s network. Use of any information
          obtained via Purple Cow Internet Inc. is at User’s risk. Neither
          Purple Cow Internet Inc., nor any of Purple Cow Internet Inc.’s
          affiliates, licensers, contractors, or their employees warrant that
          the Service will be uninterrupted, like all other ISPs.
        </p>
        <h3 id="3-terms-of-subscription">3. Terms of Subscription</h3>
        <p>
          Purple Cow Internet Inc. services are offered on a month-to-month
          subscription basis. Services shall be deemed to begin on the date on
          which Purple Cow Internet Inc. activates your Service and will end at
          the end of that month. Unless stated differently by subscriber,
          subscription will renew automatically without further action by
          subscriber unless notice is provided to Purple Cow Internet Inc. as
          per the Right to Cancel section. Further installation charges may
          apply in exceptional circumstances. Further installation fees are in
          addition to and not part of, regular service fees.
        </p>
        <h3 id="4-all-service-fees">4. All Service Fees</h3>
        <p>
          <strong>4. a)</strong> Charges and fees for each Service is set out in
          the description of each Service or bundled Services at{" "}
          <a href="http://www.purplecowinternet.com">
            www.purplecowinternet.com
          </a>
        </p>
        <p>
          <strong>4. b)</strong> Additional charges applicable to Services,
          which may Include set up or access fees, is listed at{" "}
          <a href="http://www.purplecowinternet.com">
            www.purplecowinternet.com
          </a>
        </p>
        <p>
          <strong>4. c)</strong> Purple Cow Internet will give minimum of 30
          days notice on any adjusts to fees. Subscriber’s continued use of the
          Service after any adjustment in fees shall constitute subscriber’s
          acceptance of any adjustment in fees.
        </p>
        <p>
          <strong>4. d)</strong> All charges and fees will be charged to
          subscriber monthly or yearly and will be paid in accordance with the
          payment methods set up in subscriber’s customer account. These charges
          are done automatically starting one month after receiving internet and
          will continue on that date until subscription is canceled.
          <br />
          <strong>4. e)</strong> Charges for services are non-refundable. If
          subscriber pays monthly or annually and terminates service prior to
          the end of the month or year, case dependent, there will not be
          refunds for amounts already paid.
        </p>
        <p>
          <strong>4. f)</strong> Subscriber receipts and invoices are sent to
          the email account subscriber provided at sign-up.
        </p>
        <p>
          <strong>4. g)</strong> If Purple Cow Internet Inc. is not able to
          collect payment(s) for any reason, Purple Cow Internet will attempt to
          bill again 3 days after and will continue every 3 days until paid in
          full. If outstanding amount is not paid in full subscriber’s access to
          the services will be suspended until payment has been processed
          successfully. This Includes all outstanding amounts Incurred while
          service was suspended. A reactivation fee of $39.95 will be charged
          for the re-activation of any suspended account.
          </p>
          <p>
          <strong>4. h)</strong> Purple Cow Internet Inc. reserves the right to
          terminate subscriber access to services if a balance is outstanding
          for more than ten (7) days if subscriber has failed to contact Purple
          Cow Internet Inc. with intent to resolve the balance. Purple Cow
          Internet attempts to provide three (3) or more business days notice of
          disconnect to subscriber.
        </p>
        <p>
          <strong>4. i)</strong> Unbilled, or underbilled charge will be payable
          by the subscriber when correctly adjusted. Purple Cow Internet may
          bill for charges up to one (1) year from date charge was incurred.
        </p>
        <p>
          <strong>4. j)</strong> There is a onetime $55 + tax shipping and packaging fee denoted on a subscriptions initial invoice as 'Setup Fee'.
        </p>
        <p>
          <strong>4. k)</strong> If location is deemed to not be serviceable a
          full refund will be provided. If the modem has already been shipped to
          subscriber and location is not serviceable the modem will need to be
          returned before a full refund can be issued.
        </p>
        <h3 id="4-1-credit-card-payments-and-fees">4.1 30-Day Money-Back Guarantee Terms of Service</h3>
        <p>
          <strong>4.1. a) </strong> 
          Eligibility: The 30-day money-back guarantee is only applicable to customers who are disconnecting their product entirely. For example a product could be TV, Home Phone, Internet or Cell Phone. If the product is not being disconnected, the customer is not eligible for a refund.
          The guarantee is valid for 30 days from the date you are live with the internet.
        </p>
        <p>
          <strong>4.1. b) </strong> 
          Refund Conditions: The refund applies only to the first invoice and covers all products included in the initial signup.
          There are no refunds on site survey costs.
          The modem must be returned within 30 days in the same condition it was sent.
        </p>
        <p>
          <strong>4.1. c) </strong> 
          Additional Products:
          If a customer signs up for additional products midway through the 30 days, they are not eligible for a refund on those products.
          If a customer cancels a product midway through the 30-day period but keeps the internet service, they can receive a refund for the canceled TV or Phone services.
          </p>
          <p>
          <strong>4.1. d) </strong> 
          Return Process:
          To initiate a refund, customers must contact our support team and provide necessary details.
          The refund will be processed once the modem and any other equipment are returned and received in the same condition as when sent.
          </p>
          <p>
          <strong>4.1. e) </strong> 
          General:
          This guarantee is designed to ensure customer satisfaction and trust in our services.
          Purple Cow Internet reserves the right to amend or discontinue this offer at any time without prior notice.
          </p>
        <h3 id="4-1-credit-card-payments-and-fees">
          4.2. Credit Card Payments and Fees
        </h3>
        <p>
          <strong>4.2. a)</strong> If subscriber’s credit card expires, billing
          address changes or credit card is canceled/ replaced as a result of
          loss or theft, please update credit card information in My Account
          section of the website.
        </p>
        <p>
          <strong>4.2. b)</strong> Subscriber must notify Purple Cow Internet
          Inc. in writing within ten (10) days of credit card statement for
          disputes of Purple Cow Internet Inc.’s charges on said statement or
          dispute will be deemed waived. Please direct billing disputes to
          team@purplecowinternet.com.
        </p>
        <p>
          <strong>4.2. c)</strong> Subscriber’s initial use of service
          authorizes Purple Cow Internet Inc. to charge subscriber’s credit card
          account number filed with Purple Cow Internet for charges and taxes.
          It also allows Purple Cow Internet to auto debit monthly bills on the
          monthly anniversary date of when the system was turned on. This
          authorization will remain valid for sixty (60) days after subscription
          of services termination date provided to Purple Cow Internet.
        </p>
        <p>
          <strong>4.2. d)</strong> If a credit card payment fails, a $5
          administrative charge will be applicable if payment is not made by the
          due date, as stated on the invoice. This administrative charge will be
          reflected in the consecutive month’s invoice.
        </p>
        <p>
          <strong>4.2. e)</strong> If a chargeback is initiated, Purple Cow
          Internet Inc. reserves the right to charge $35.00 to cover
          administrative and processing costs of the chargeback. If the
          chargeback is deemed invalid, Purple Cow Internet Inc. reserves the
          right to dispute and reverse the chargeback. In addition, Purple Cow
          Internet Inc. may suspend services if the chargeback puts subscriber’s
          account in state of delinquency.
          <br />
          <strong>4.2. f)</strong> If subscriber’s account is
          suspended/deactivated due to non-payment, Purple Cow Internet Inc.
          reserves the right to charge the credit card account on file for
          balance owing, Including applicable taxes and fees.
        </p>
        <p>
          <strong>4.2. g)</strong> Subscribers monthly billing does not begin
          until they are live with the internet or one week after the modem is
          activated and delivered to subscribers home.
        </p>
        <h3 id="4-2-bill-payments">4.3. Bill Payments</h3>
        <p>
          <strong>4.3. a)</strong> A $5 administrative charge will apply if
          payment is not made by due date, as stated on invoice. This
          administrative charge will be reflected on the consecutive month’s
          invoice.
        </p>
        <h3 id="4-3-technicians">4.4. Technicians</h3>
        <p>
          <strong>4.4. a)</strong> If your home requires, a new line, repair, or
          connection work a technician one will be booked by Purple Cow
          Internet. You will receive a Morning or Afternoon window on the
          closest date to your requested install date. These technicians are
          subcontracted through another internet service provider and after
          requesting an appointment it takes approx 24 - 48 hour time period to
          schedule. If the technician does not show up during the scheduled time
          one will be rebooked free of charge. There are no refunds or credits
          added to subscribers account if technician does not show up to
          scheduled appointment.
        </p>
        <p>
          <strong>4.5. b)</strong> In some very rare cases a site survey can be
          required if your current place of service has never been connected to
          the network before. We will know if a site survey is required in 3 - 5
          business days of signup. If you would like to move forward with the
          site survey a 3rd party technician can be hired at the cost of $450.
          Upon approval Purple Cow Internet will bill the card on file a
          non-refundable $450 for 3rd party to perform this service. In moving
          forward with the Site Survey there is no guarantee that you will be
          able to get service and or there could be additional charges the 3rd
          party may require to build out infrastructure to connect your home.
        </p>
        <h3 id="5-website-information-accuracy">
          5. Website Information Accuracy
        </h3>
        <p>
          Purple Cow Internet Inc. endeavors to provide accurate and up-to-date
          information. In the event that a typo or other error occurs, Purple
          Cow Internet Inc. reserves the right correct such misprint without
          liability to Purple Cow Internet Inc.
        </p>
        <h3 id="6-website-and-service-termination-of-use">
          6. Website and Service Termination of Use
        </h3>
        <p>
          <strong>6. a)</strong> At any time, and for any reason, Purple Cow
          Internet Inc. reserves the right in its discretion to terminate or
          restrict subscriber access to website and services without notice.
          Purple Cow Internet Inc. may terminate or restrict service if payment
          is delinquent or service is abused. Purple Cow Internet Inc. deems
          abuse as: (i) using service for telemarketing purposes. (ii) using
          service for prank calling. (iii) using service for autodialing, modem
          or faxing (Including, fax broadcasting and fax blasting); (iv) using
          services for anything other than expected residential use.
        </p>
        <p>
          <strong>6. b)</strong> In some cases, Purple Cow Internet Inc. may
          terminate access to service by providing subscriber with ten (10) days
          notice. Purple Cow Internet Inc. may then refund subscriber a
          pro-rated amount of any prepaid amounts received prior based on the
          amount of time remaining in the prepaid term.
        </p>
        <h3 id="7-right-to-cancel">7. Right to Cancel</h3>
        <p>
          <strong>7. a)</strong>Subscriber may discontinue Purple Cow Internet
          Inc. services for any reason, at any time, with notice as defined
          below: (i) Request to cancel must be made via telephone or text
          message. (ii) For monthly subscriptions, subscriber should use best
          efforts to notify Purple Cow Internet Inc. to provide a minimum of ten
          (10) business days notice. Cancellations will occur at the end of the
          current billing cycle. (iii) There will be no refunds for
          cancellations if service has been delivered.
          <br />
          <strong>7. b)</strong> Subscriber agrees that service may be
          disconnected at any time on the disconnection date. If the requested
          disconnect date falls on a weekend/holiday, date of disconnection will
          be made the prior business day.
          <br />
          <strong>7. c)</strong> The modem must be returned in good working
          condition within 30 days of cancelation or a $150 charge will be
          applied to customers account. If the modem is returned after the 30
          days and the customer has been charged for the modem, the $150 will be
          retuned to the customer within 10 business days.
          <br />
          <strong>7. d)</strong> If there is a refund that is deemed this
          refunded will be processed when the modem is returned, processed and
          in good working condition.
        </p>
        <h3 id="8-privacy-at-purple-cow-internet-inc-">
          8. Privacy at Purple Cow Internet Inc.
        </h3>
        <p>
          By accepting these Terms and Conditions of Use, subscriber consents to
          Purple Cow Internet Inc.’s collection and use of personal information
          in accordance with Purple Cow Internet Inc.’s Privacy Policy without
          notice or liability to subscriber or any other person
        </p>
        <h3 id="9-website-and-services-are-provided-as-it-stands">
          9. Website and Services are Provided As it Stands
        </h3>
        <p>
          Purple Cow Internet Inc.’s website and services are provided as
          available, and without any representations of any kind. The operation
          of website and services can be impacted by many factors beyond the
          control of Purple Cow Internet Inc. Privacy and security risks cannot
          be eliminated. Purple Cow Internet Inc. may restrict destinations
          deemed to result in fraudulent abuse of Purple Cow Internet Inc.’s
          network. These areas Include but are not limited to 902-XXX-XXXX and
          782-XXX-XXXX.
        </p>
        <h3 id="10-limitation-of-liability-and-damages-waiver">
          10. Limitation of Liability and Damages Waiver
        </h3>
        <p>
          Purple Cow Internet Inc. cannot guarantee completely uninterrupted
          operation of services, nor shall it be liable for failures,
          interruption or breakdown of service in the network or any other
          network or connection involved in the use of services regardless of
          cause and interruption time. Purple Cow Internet Inc. and its
          affiliates, employees, agents or representatives do not make any
          warranty, representation, or guarantee of any nature regarding any
          service, product or Purple Cow Internet Inc. facilities provided by
          Purple Cow Internet Inc. to subscriber, either express or implied,
          Including without limitation, those relating to: (i) Fitness for a
          specific purpose or merchantability; (ii) Network transmission
          capacity; (iii) The security of any transaction; (iv) The fault
          tolerance of services or suitability of same for high risk activities;
          or (v) The reliability and compatibility of the Purple Cow Internet
          Inc. facilities/equipment or software of third parties which may be
          used by Purple Cow Internet Inc. in providing, or by subscriber in
          using the services and all warranties and representations/conditions,
          express or implied, are permitted by applicable law, hereby excluded.
          The subscriber agrees that subscriber has not received nor relied upon
          any representation, warranty, condition or guarantee made by Purple
          Cow Internet Inc.
        </p>
        <h3 id="11-user-information">11. User Information</h3>
        <p>
          Purple Cow Internet Inc. will not publish subscriber’s name and will
          not make such information available to third parties, unless required
          by court order.
        </p>
        <h3 id="12-non-transferable">12. Non-transferable</h3>
        <p>
          The right to use Purple Cow Internet Inc. service is non-transferable.
        </p>
        <h3 id="13-intellectual-property-notices">
          13. Intellectual Property Notices
        </h3>
        <p>Copyright © 2018 Purple Cow Internet Inc.</p>
        <h3 id="14-notice-of-intellectual-property">
          14. Notice of Intellectual Property
        </h3>
        <p>
          <strong>14. a)</strong> The website, the service and all content,
          images, software, icons, applications, design, and other elements
          available on/through Purple Cow Internet Inc.’s website and provided
          in correlation with services are the property of Purple Cow Internet
          Inc., and others, and are protected by Canadian and international
          copyright, trademark, and other laws. Subscriber’s use of Purple Cow
          Internet Inc.’s website and service does not transfer any ownership or
          other rights in the website or its content and services to the
          subscriber. “Purple Cow Internet Inc.”, together with all integrated
          logos, slogans, and graphics, is a trademark of Purple Cow Internet
          Inc., and must not be utilized or reproduced without Purple Cow
          Internet Inc.’s prior written permission.
          <br />
          <strong>14. b)</strong> Other company names, products, and logos that
          appear on the website may be registered or unregistered trade-names,
          trade-marks and service-marks of their owners. Any use of these
          trade-names, trade-marks and/or service-marks and logos displayed on
          the Purple Cow Internet Inc. website is prohibited.
        </p>
        <h3 id="15-governing-law">15. Governing Law</h3>
        <p>
          The Terms and Conditions of use, subscriber’s use of the website and
          services, any and all related matters are governed solely by the laws
          of the Province of Nova Scotia and applicable federal laws of Canada.
        </p>
        <h3 id="16-force-majeure">16. Force Majeure</h3>
        <p>
          Neither subscriber nor company will be liable for breach of these
          Terms and Conditions of Use, nor for failure in performance resulting
          from any cause beyond each party’s control, Including, and not limited
          to, storm, flood, fire, lockout, strike or other workforce trouble,
          war, rebellion, riot, accident, blackout, acts of God, or any failure
          of third-party suppliers.
        </p>
        <h3 id="17-contact-information">17. Contact Information</h3>
        <p>
          Please direct any questions regarding these Terms and Conditions of
          Use or the general website to Purple Cow Internet Inc. support’s phone
          at 902 800-2660, or email to team@purplecowinternet.com
        </p>

        <p>
          Here is a list of numbers we may text from:
          <li>787753</li>
          <li>18333311163</li>
          <li>19022006027</li>
          <li>19025183370</li>
          <li>19025183371</li>
          <li>19025183372</li>
          <li>19025183373</li>
          <li>19025183374</li>
          <li>19026001963</li>
          <li>19028002660</li>
          <li>19028121824</li>
          <li>19029674877</li>
      
          Here is a list of numbers we may call from:
          <li>+1 902-904-5773</li>

        </p>
        <h3 id="18-failure-to-comply">18. Failure to Comply</h3>
        <p>
          Purple Cow Internet Inc. may deny subscriber access to services
          without notice if subscriber engages in conduct or activities that
          violate any of the terms and conditions in this agreement as Purple
          Cow Internet Inc., in its sole discretion, believes.
        </p>
        <h3 id="19-shipping-and-handling">19. Shipping and Handling</h3>
        <p>
          The cost of shipping is not Included with any orders or returns,
          unless stated otherwise. The subscriber agrees to pay for all
          shipping/handling of equipment, in addition to all fees for service.
          General shipping cost is $10 unless specifically stated differently.
          If the items are damaged on receipt of package the subscriber agrees
          to pay the full value of the product. For example the DG2470 is $150.
        </p>
        <h3 id="20-miscellaneous">20. Miscellaneous</h3>
        <p>
          <strong>20. a)</strong> Subscriber agrees to notify Purple Cow
          Internet Inc., if subscriber changes civic address, mailing address or
          phone number, and to report a legal name, postal address, and
          telephone number.
          <br />
          <strong>20. b)</strong> Accounts are created with receipt of payment.
          Billing period starts on date of service installation. Purple Cow
          Internet Inc. invoices one (1) month in advance, with the exception of
          per-minute long-distance usage, which is billed on the first of each
          consecutive month. Services require payment via credit card only. The
          subscriber is responsible for updating any information on his/her
          Purple Cow Internet Inc. account
          <br />
          <strong>20. c)</strong>By agreeing to the terms of service you have opted 
          into receiving text messages regarding your Internet with Purple Cow Internet, 
          message and data rates may apply. Message frequency varies depending on the activity of your record. 
          Purple Cow Internet does not share SMS opt-in consent of their customers to any 3rd party organizations. 
          You can opt out by responding STOP at any time.
        </p>
        <h3 id="21-purple-cow-internet-inc-long-distance-terms">
          21. Purple Cow Internet Inc. Long-Distance Terms
        </h3>
        <p>
          <strong>21. a)</strong> Subscriber indemnifies Purple Cow Internet
          Inc. and its partners for any penalties or charges received as a
          result of using the service improperly, i.e., calling the
          long-distance access number for any service. Subscriber may access and
          utilize services solely for subscriber’s own residential and personal
          use. Service is to be used for voice purposes only. Subscriber may not
          transfer or resell service to other person(s) for any purpose or
          charge other person(s) for the use Purple Cow Internet Inc. services.
          This service does not support: (i) “011” calling, except where
          specified on the rates page; (ii) “1-900”, “1-976” number calling; or
          (iii) “311”, “411”, or other x11 services in any service areas, with
          the exception of e911; (iv) operator assistance calling.
        </p>
        <h4 id="22-purple-cow-internet-inc-high-speed-internet">
          22. Purple Cow Internet Inc. High Speed Internet
        </h4>
        <p>
          <strong>22. a)</strong> Prohibited Uses
          <br />
          <strong>22.a) b)</strong> The subscriber may not send spam when dialed
          into the Purple Cow Internet Inc. network. The following types of
          email sent constitute as spam:
        </p>
        <p>
          <strong>22. c)</strong> forged e-mails to/from address other than from
          actual sender, or to actual recipient;
          <br />
          <strong>22. d)</strong> message subject not related to email body;
        </p>
        <p>
          <strong>22. e)</strong> email not requested by the recipient;
        </p>
        <p>
          <strong>22. f)</strong> emails sent in bulk by sender;
        </p>
        <p>
          <strong>22. g)</strong> mail bombs;
          <br />
          <strong>22. h)</strong> transmit objectionable information which
          contains a virus, cancelbot, Trojan horse, worm or another harmful
          component;
          <br />
          <strong>22. i)</strong> allow users to run any form auto-responds from
          subscriber’s account;
          <br />
          <strong>22. j)</strong> the unauthorized use of Relay Servers.
          Subscriber agrees that any unauthorized use of any e-mail server for
          the purposes of relaying or distributing messages is prohibited;
          <br />
          <strong>22. k)</strong> purposeful alteration or fraudulent
          addressing. The subscriber may not intentionally misrepresent
          information of any e-mail messages sent while using Purple Cow
          Internet Inc. service.
        </p>
        <h3 id="23-installation">23. Installation</h3>
        <p>
          <strong>23. a)</strong> Purple Cow Internet Inc. strives to deliver
          and install high-speed Internet service by estimated activation date.
          However, delays can occur due to external factors, i.e., subscriber’s
          availability or the acts of third-party suppliers/providers. Purple
          Cow Internet Inc. cannot guarantee services will be delivered and
          installed by a specific activation date.
          <br />
          <strong>23. b)</strong> Subscriber’s demarcation point for the
          high-speed services shall be a general location where all external
          wiring feeds terminate and connect with inside wiring owned by
          subscriber. High-speed Internet services will be installed up to the
          subscriber demarcation point.
          <br />
          <strong>23. c)</strong> Purple Cow Internet Inc. is not responsible
          for any equipment/wiring beyond the demarcation point, with the
          exception of Purple Cow Internet Inc.’s subscriber premise equipment,
          i.e. Purple Cow Internet Inc. supplied modem.
          <br />
          <strong>23. d)</strong> Purple Cow Internet Inc. is not responsible
          for configuration of customer premise equipment (CPE) above and beyond
          standard configuration. Purple Cow Internet Inc. is not responsible
          for configuration of customer-owned devices (CPE, laptops, PCs, etc.).
          <br />
          <strong>23. e)</strong> Additional wiring and/or termination of
          circuits past the subscriber demarcation point will be the
          subscriber’s sole responsibility. In the event the subscriber requests
          other wiring work to be performed by Purple Cow Internet Inc., this
          work will be performed by a Purple Cow Internet Inc. authorized
          installer and will be invoiced to the subscriber by the Purple Cow
          Internet Inc. authorized installer.
        </p>
        <h3 id="24-credits-for-service-interruption">
          24. Credits for Service Interruption
        </h3>
        <p>
          <strong>24. a)</strong> If services are interrupted for more than a
          period of 24 hours after the subscriber notifies Purple Cow Internet
          Inc., a credit equal to 1/30th of any fixed billing cycle charges for
          the affected service will apply to every full 24 hour period during
          which the interruption remains. Credit in any billing period will not
          exceed the total average monthly charges for that period for the
          affected service. Credits will not apply to any non-recurring or usage
          charges. The subscriber’s sole and exclusive remedy for service
          interruption will be the credit as outlined above.
          <br />
          <strong>24. b)</strong> The subscriber must request credit within
          thirty (30) days of any validated interruption. Without this request,
          the subscriber forever waives right to any credit for that outage.
          Purple Cow Internet Inc. will in its sole and absolute discretion,
          determine if an interruption is considered a validated interruption.
          <br />
          <strong>24. c)</strong> Multiples resolutions will at no time be
          provided to the subscriber for a single event. Subscriber payables
          must be current prior to the subscriber receiving credits.
        </p>
        <h3 id="no-credits-are-provided-for-interruptions-due-to-the-following-">
          No credits are provided for interruptions due to the following:
        </h3>
        <p>
          <strong>24. e)</strong> the failure of any component, equipment,
          wiring, network or system provided by subscriber or third party;
        </p>
        <p>
          <strong>24. f)</strong> network maintenance by Purple Cow Internet
          Inc.;
        </p>
        <p>
          <strong>24. g)</strong> Force Majeure events;
        </p>
        <p>
          <strong>24. h)</strong> omissions or acts, negligent or otherwise, by
          subscriber or third-party, or any breach of the terms and conditions
          of use by the subscriber of the service agreement or any schedules or
          addenda attached at any time.
        </p>
        <h3 id="25-information-technology-master-plan-itmp-">
          25. Information Technology Master Plan (ITMP)
        </h3>
        <p>
          Purple Cow Internet Inc.’s fair share policy endeavors to provide its
          customers with a better Internet experience.
        </p>
        <p>
          Purple Cow Internet Inc., like many Internet providers in Canada, may
          occasionally be required to manage its network so that no application,
          service or subscriber consumes more than their fair share of bandwidth
          of which to impede the utilization and enjoyment of other subscribers.
        </p>
        <p>
          Purple Cow Internet Inc.’s fair share policy strives to improve each
          subscriber’s online experience. Improved performance can be noted
          with: Email Applications, “Real-Time” Browser Applications, Internet
          Radio, Streaming Instant Messaging (IM), Online Gaming, Netflix,
          Youtube, etc. in addition, “client-server” downloads Including and not
          limited to software updates, iTunes, and Microsoft Windows updates.
        </p>
        <p>
          The fair share policy exists to ensure the best quality Internet is
          available to the maximum number of subscribers. Purple Cow Internet
          Inc. continues to update and ensure this policy is up to date,
          regularly.
        </p>
        <h3 id="26-voip-voice-service">26. VOIP Voice Service</h3>
        <p>26. a) Service Availability</p>
        <p>
          Subscriber may qualify for VOIP Voice Services, depending on
          subscriber address. Qualification must be verified by contacting
          Purple Cow Internet Inc. customer service.
        </p>
        <h3 id="27-enhanced-911-e911-service">
          27.Enhanced 911 (E911) Service
        </h3>
        <p>Important 9-1-1 Information</p>
        <p>
          We want to make sure that you are aware of important differences in
          the way 9-1-1 service operates with a VoIP phone when compared with
          traditional telephone service. Here’s what you need to keep in mind:
        </p>
        <h3 id="differences-between-traditional-9-1-1-service-and-voip-phone-9-1-1">
          DIFFERENCES BETWEEN TRADITIONAL 9-1-1 SERVICE AND VOIP PHONE 9-1-1
        </h3>
        <p>
          With traditional phone services, your 9-1-1 call is sent directly to
          the nearest emergency response centre. With VoIP phone service, your
          9-1-1 call is forwarded to a third-party service provider that will
          automatically or manually route your call to the emergency response
          centre.
        </p>
        <h3 id="remember-to-verify-your-location">
          REMEMBER TO VERIFY YOUR LOCATION
        </h3>
        <p>
          Because you can move your VoIP phone between locations and because,
          for technical reasons, the emergency operator may not have your name,
          location or contact information available, you must immediately inform
          the emergency operator of your location and contact particulars any
          time you call 9-1-1. Do not risk sending police or ambulance services
          to the wrong location.
        </p>
        <h3 id="be-prepared-during-any-service-interruption">
          BE PREPARED DURING ANY SERVICE INTERRUPTION
        </h3>
        <p>
          VoIP phone service depends not only on your continued subscription
          (and payment) for the service, but also on Internet connectivity and
          power to function. In the event of power, network, or Internet outages
          (including congestion), or if your service is disconnected or
          suspended due to non-payment, you may experience a failure, disruption
          or delay in your 9-1-1 service. We recommend that you keep an
          alternative phone service (such as a cellular telephone) handy to
          increase the reliability of your access to emergency services during
          any service interruption.
        </p>
        <h3 id="do-not-disconnect">DO NOT DISCONNECT</h3>
        <p>
          Until you are told to do so by an emergency dispatcher, do not
          disconnect your 9-1-1 call. If you are inadvertently disconnected,
          call back immediately.
        </p>
        <h3 id="keep-your-service-address-up-to-date-">
          KEEP YOUR SERVICE ADDRESS UP TO DATE.
        </h3>
        <p>
          Pulsar360 will attempt to provide the emergency operator with your
          service address, so please ensure that your information on file with
          us is always accurate and updated. If you do not do this (for example,
          if you are unable to speak during a 9-1-1 call), the emergency
          operator may assume that you are calling from the last registered
          address. Canadian Customers may update location information by filling
          out and submitting a form that can be accessed at:{" "}
          <a href="//dhphone.securetransit.net/home/page_members.htm">
            https://dhphone.securetransit.net/home/page_members.htm
          </a>
          . US Customers should log into their Account Portal at{" "}
          <a href="//pulsar360.zendesk.com/">https://pulsar360.zendesk.com</a>{" "}
          and use the Submit A Request Link to update information. Canadian and
          US Customers may also update location information by contacting
          Pulsar360 by email at{" "}
          <a href="mailto:support@pulsar360.com">support@pulsar360.com</a>{" "}
          877-743-9128.or call by calling
        </p>
        <h3 id="inform-other-users">INFORM OTHER USERS</h3>
        <p>
          You must notify members of your household and other potential users of
          your VoIP phone service of the nature and limitations of 9-1-1
          emergency calls. To make this easier, attach the included stickers in
          a visible location on your telephone sets.
        </p>
        <h3 id="limitations-of-liability">LIMITATIONS OF LIABILITY</h3>
        <p>
          Pulsar360’s terms of service limit and disclaim liability related to
          VoIP 9-1-1 service. Those limitations and disclaimers are contained in
          your service contract for VoIP Services, so please read these
          carefully.
        </p>
        <h3 id="if-you-have-questions-regarding-your-9-1-1-service-please-contact-our-offices">
          IF YOU HAVE QUESTIONS REGARDING YOUR 9-1-1 SERVICE PLEASE CONTACT OUR
          OFFICES
        </h3>
        <h3 id="28-purple-cow-internet-inc-does-not-support-operator-assisted-or-1-900-calls-">
          28. Purple Cow Internet Inc. Does Not Support Operator Assisted or
          1-900 calls.
        </h3>
        <p>
          Purple Cow Internet Inc.’s VOIP Voice Service does not support 0+ or
          operator assisted calls (Including and not limited to collect calls
          and third-party billing calls). Additionally, the VOIP Voice Service
          does not support 1-900 calls/services. The VOIP Voice Service does
          support 411 calls (411 calls are charged at a rate to be determined
          from time to time by Purple Cow Internet Inc.), and 311 calls are
          supported where available. The VOIP Voice Service may support certain
          calling card calls and/or company flat rate calling services (dialing
          requirements dependent), but it will not support 10-10 dial, around
          long-distance services.
        </p>
        <h3 id="29-porting">29. Porting</h3>
        <p>29. a)Number Transfer (“porting to Purple Cow Internet Inc.”).</p>
        <p>
          If the subscriber wishes to transfer/port a current telephone number
          to Purple Cow Internet Inc., the subscriber must provide authorization
          in a Letter of Authorization (LOA) and submit it to Purple Cow
          Internet Inc. first. The LOA will permit Purple Cow Internet Inc. to
          contact subscriber’s current carrier and request porting of the number
          to the Purple Cow Internet Inc. network. It is the subscriber’s
          responsibility to cancel the service agreement with the current
          carrier once informed that the port is complete by Purple Cow Internet
          Inc.
        </p>
        <p>
          Purple Cow Internet Inc. is not responsible for numbers that are lost,
          charges that are Incurred for telephone number reclamations, or any
          other porting charges should the subscriber cancel service before the
          port is complete. If a number transfer is rejected due to information
          provided by the subscriber, Purple Cow Internet Inc. reserves the
          right to charge a $50 fee for port rejection.
        </p>
        <h3 id="29-b-number-transfer-from-purple-cow-internet-inc-porting-out-">
          29. b) Number Transfer from Purple Cow Internet Inc. (“porting out”).
        </h3>
        <p>
          If the subscriber decided to switch to another service provider, the
          subscriber may request to transfer, or “port out”, the telephone
          number supplied by Purple Cow Internet Inc. to another service
          provider. If the subscriber requests a new service provider to port a
          number from Purple Cow Internet Inc., it is recommended that the
          subscriber contact Purple Cow Internet Inc. after the port is complete
          so that Purple Cow Internet Inc. can ensure the accurate cancellation
          of billing. The subscriber will remain responsible for any
          fees/charges associated with said number until the service is
          terminated. If the subscriber’s service has been suspended due to
          non-payment, Purple Cow Internet Inc. may deny the port-out request at
          their discretion. If a port is unsuccessful for any reason, the
          service and this agreement will not terminate, and the subscriber will
          continue to be responsible for charges associated with the service and
          the subscriber’s account.
        </p>
        <h3 id="29-c-directory-listing-">29. c) Directory listing.</h3>
        <p>
          All telephone numbers that the subscriber obtains in connection with
          Voice Services will not be listed in any telephone directories.
        </p>
        <h3 id="30-calling-plans-acceptable-usage">
          30. Calling Plans &amp; Acceptable Usage
        </h3>
        <p>
          <strong>30. a)</strong> Details for long distance services Included
          with the VOIP Voice Service: Calls made to locations that not Included
          in the VOIP Voice Service are charged to the subscriber’s account in
          accordance with Purple Cow Internet Inc’s terms and conditions.
          <br />
          <strong>30. b)</strong> Areas excluded from Purple Cow Internet Inc’s
          Canada and free United States calling plans Include: Yukon, Nunavut,
          Northwest Territories, Hawaii, and Alaska).
          <br />
          <strong>30. c)</strong> Acceptable Usage. Purple Cow Internet Inc.’s
          Acceptable Use Policy helps subscribers understand the permissible and
          intended use of its services, and to prevent fraud and abuse of
          unlimited calling plans and features. Unlimited long-distance plans
          are intended for residential and personal, non-commercial use. Only
          the account holder and/or residential family members are permitted
          use.
        </p>
        <h3 id="31-installation-">31. Installation.</h3>
        <p>
          The subscriber is responsible for the installation of the VOIP Voice
          Service. Subscriber can request a technician, in which case Purple Cow
          Internet Inc. will arrange an authorized installer to perform the
          work. This will be billed directly to the subscriber by the Purple Cow
          Internet Inc. authorized installer. Purple Cow Internet Inc. standard
          pricing does not Include upgrades in wiring, cabling, routers or
          additional equipment needed for the subscriber’s home to be readied
          for installation. Subscriber is responsible to make and pay for any
          necessary changes/upgrades. If the subscriber fails to provide, ready
          or maintain the location(s) and facilities for the installation of
          VOIP Voice Services, Purple Cow Internet Inc. will not be liable for
          resulting delays in commencing services, service interruption, and/or
          related damages, and the subscriber will be liable for additional
          costs Incurred by Purple Cow Internet Inc. to successfully install or
          restore these services.
        </p>
        <h3 id="32-fraudulent-content">32. Fraudulent Content</h3>
        <p>
          The subscriber is responsible for the distribution of fraudulent
          materials if communicated. Purple Cow Internet Inc. reserves the right
          to bill a subscriber’s credit card if violation results in costs of
          any kind to Purple Cow Internet Inc.
        </p>
        <h3 id="33-restricted-content">33. Restricted Content</h3>
        <p>
          The subscriber will not post or transmit any of the following
          restricted contents: Illegal and/or unethical activities Including
          violations of privacy, pornography, obscenity, nudity, hacking,
          computer viruses, and any harmful matter as determined by Purple Cow
          Internet Inc. Subscriber agrees to indemnify and hold Purple Cow
          Internet Inc. non-liable for any claim resulting from subscriber
          publications or said restricted matter.
        </p>
        <h3 id="34-reconnect-limit-minimum">34. ReConnect Limit Minimum</h3>
        <p>
          The subscriber understands they cannot log back on for a period of one
          (1) minute following the termination of an online session.
        </p>
        <h3 id="35-email-account">35. Email Account</h3>
        <p>Purple Cow Internet Inc. cannot provide email accounts.</p>
        <h3 id="36-equipment">36. Equipment</h3>
        <p>
          <strong>36. a)</strong> Certain services offer the subscriber the
          option of purchasing/renting hardware or equipment from Purple Cow
          Internet Inc.
          <br />
          <strong>36. b)</strong> Equipment is to be operated in compliance with
          the general specifications and standards of the manufacturer and
          applicable laws. The subscriber will use provided equipment in the
          manner for which it is designed with respect.
          <br />
          <strong>36. c)</strong> For rented equipment, the subscriber will not
          sell, lease or dispose of the equipment (or any of its components) and
          will keep equipment free of impediments. Purchased and paid for
          equipment is excluded.
          <br />
          <strong>36. d)</strong> The subscriber bears the risk of losses,
          destruction, or confiscation of equipment once equipment is delivered
          to their address.
          <br />
          <strong>36. e)</strong> The subscriber will not alter the equipment’s
          components, software, firmware or any other piece of the equipment,
          excluding what is provided on Purple Cow Internet Inc.’s support page.
          Any modifications beyond this can hinder function of equipment and
          will not be supported by Purple Cow Internet Inc. If these alterations
          prevent/alter the capacity of the modem, the subscriber will be
          charged repair fees as determined by Purple Cow Internet Inc. If the
          modem is unrepairable, the subscriber is responsible for loss and must
          pay the full purchase price of the equipment(s).
          <br />
          <strong>36. f)</strong> For modem exchanges, the subscriber will be
          charged a modem exchange in addition to shipping/delivery fees for new
          equipment and/or return of previous equipment.
          <br />
          <strong>36. g)</strong> In terminating this agreement, or certain
          service hereunder, the subscriber forfeits all equipment(s).
          Equipment(s) must be returned to Purple Cow Internet Inc. within
          fifteen (15) business days from termination date, failing which, the
          subscriber will be charged the full purchase price of said equipment.
          Returned equipment must Include all components, original cables and
          power adapters, without damage.
          <br />
          <strong>36. h)</strong> Purple Cow Internet Inc. does not support the
          use of third-party hardware supplied outside of Purple Cow Internet
          Inc.
          <br />
          <strong>36. i)</strong> Purchased hardware Includes a one (1) year
          manufacturer’s warranty. Defective hardware can be exchanged within
          that time period. Subscriber is responsible for shipping/delivery
          fees.
        </p>
        <h3 id="37-service-specifications">37. Service Specifications</h3>
        <p>
          <strong>37. a)</strong> Service speed depends on advertised
          upload/download speeds listed on Purple Cow Internet Inc.’s website,
          i.e., the speed “up to 10 Mbps” means the subscriber can expect speeds
          up to 10Mbps or less.
          <br />
          <strong>37. b)</strong> Purple Cow Internet Inc. does not make
          guarantees for speed of the subscriber’s connection as speeds vary on
          external factors outside of Purple Cow Internet Inc.’s control.
          <br />
          <strong>37. c)</strong>Purple Cow Internet Inc. strives to deliver and
          install services by the activation date. However, delays can occur due
          to external factors i.e. subscriber’s availability or the acts of
          third-party suppliers/providers. Purple Cow Internet Inc. cannot
          guarantee services will be delivered and installed by a specific
          activation date.
        </p>
        <h3 id="38-service-installation-and-technician-appointments">
          38. Service Installation and Technician Appointments
        </h3>
        <p>
          <strong>38. a)</strong> If a technician(s) must be dispatched to the
          subscriber’s premise, the subscriber will be notified by Purple Cow
          Internet Inc. and agrees upon a specific date and time frame, and it
          is expected that someone will be home on that date during that time
          frame in order for the technician to access the premise. If no one is
          home and the technician can not gain access to the household, the
          subscriber will be charged a $25 fee. This fee also applies if the
          technician is refused access to the premise for any other reason.
          <br />
          <strong>38. b)</strong> Additional wiring and/or termination of
          circuits past the subscriber demarcation point will be the
          subscriber’s sole responsibility. In the event the subscriber requests
          other wiring work to be performed by Purple Cow Internet Inc., this
          work will be performed by a Purple Cow Internet Inc. authorized
          installer and will be invoiced to the subscriber by the Purple Cow
          Internet Inc. authorized installer.
        </p>
        <h3 id="39-purple-cow-internet-inc-subscriber-referral-program">
          39. Purple Cow Internet Inc. Subscriber Referral Program
        </h3>
        <p>
          <strong>39. a)</strong> Purple Cow Internet Inc. rewards subscribers
          for referrals to service. Each successful referral to any Purple Cow
          Internet bundle (Voice and High-Speed Internet packages), subscriber
          receives a credit of $5.00 for each month their referral is
          subscribed, for a maximum of 12 months.
          <br />
          <strong>39. b)</strong> Referral credits are valid only for referrals
          to Purple Cow Internet bundle services (Voice and High-Speed Internet
          bundles).
          <br />
          <strong>39. c)</strong> Referrer name and/or telephone number must be
          given at time of, or within fifteen (15) days of sign-up. Referral
          credits cannot be backdated or retroactively applied for existing
          subscribers.
          <br />
          <strong>39. d)</strong> The Referral Program is not available for
          wholesale customers.
          <br />
          <strong>39. e)</strong> Purple Cow Internet Inc. may terminate this
          promotion at any time for any reasons. Upon termination, any existing
          (in progress) referral credits will continue as promised for up to
          twelve (12) months.
        </p>
        <h3 id="40-subscriber-responsibilities">
          40. Subscriber Responsibilities
        </h3>
        <p>
          <strong>40. a)</strong> The subscriber is responsible for acquiring
          and maintaining software required to make use of the Purple Cow
          Internet Inc. connection. Purple Cow Internet Inc. cannot provide
          warranties for Internet, email, or other connection software
          subscriber runs on their computer(s). Purple Cow Internet Inc. assumes
          no responsibility for damages resulting from the use of said software.
          <br />
          <strong>40. b)</strong> It is the subscriber’s responsibility to
          ensure use of their account does not result in damage/disruption to
          Purple Cow Internet Inc’s Internet services.
        </p>
        <h3 id="41-service-support">41. Service Support</h3>
        <p>
          <strong>41. a)</strong> Purple Cow Internet Inc. will offer support
          for configurations of Internet but cannot provide support for other
          system software.
          <br />
          <strong>41. b)</strong> Purple Cow Internet Inc. will support
          purchased or rented DSL hardware from Purple Cow Internet Inc., but
          cannot support hardware purchased or rented externally.
        </p>
        <h3 id="42-minimum-system-requirements">
          42. Minimum System Requirements
        </h3>
        <p>
          The subscriber is responsible for ensuring that their computer meets
          minimum system requirements. If said computer or other hardware does
          not meet these requirements, Purple Cow Internet Inc. is not
          responsible for connection challenges experienced.
        </p>
        <h3 id="43-resale">43. Resale</h3>
        <p>
          Resale of Purple Cow Internet Inc. bandwidth or any other part of the
          high-speed service is strictly prohibited.
        </p>
        <h3 id="entire-agreement">Entire Agreement</h3>
        <p>
          These Terms and Conditions establish the full and entire agreement
          between the subscriber and Purple Cow Internet Inc. and supersede
          any/all prior agreements regarding these Terms and Conditions of Use.
        </p>
      </section>
    </LayoutNew>
  )
}
